import React, { useEffect, useState } from 'react';

type FeatureFlagConfig = {
  testEnvironment: boolean;
  partnerServiceAgreements: boolean;
  excludeSupplementalFee: boolean;
  feedbackUI: boolean;
  onboardingAutoGetPaidNow: boolean;
  vendorSurvey: boolean;
  onDemandServiceAgreement: boolean;
  onDemandOnboardingSkipsBankAccountStep: boolean;
  showAdvancedFilters: boolean;
  invoiceUploading: boolean;
};

const defaultFeatureFlags: FeatureFlagConfig = {
  testEnvironment: true,
  partnerServiceAgreements: false,
  excludeSupplementalFee: false,
  feedbackUI: false,
  onboardingAutoGetPaidNow: false,
  vendorSurvey: false,
  onDemandServiceAgreement: false,
  onDemandOnboardingSkipsBankAccountStep: true,
  showAdvancedFilters: false,
  invoiceUploading: false,
};

export const FeatureFlagsContext = React.createContext(defaultFeatureFlags);

type FeatureFlagsProviderProps = {
  children: React.ReactNode;
};

export function FeatureFlagsProvider({ children }: FeatureFlagsProviderProps) {
  const [features, setFeatures] = useState<FeatureFlagConfig>(defaultFeatureFlags);

  useEffect(() => {
    const featureFlagConfig = CONFIG.featureFlags;
    setFeatures(featureFlagConfig);
  }, [children]);

  return (
    <FeatureFlagsContext.Provider value={features}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export const useFeatureFlags = () => React.useContext(FeatureFlagsContext);
