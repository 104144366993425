import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISODateString, ProgramCodes } from '@mesa-labs/mesa-api/dist/types';

export type Brand = 'jll' | 'newmark' | 'cbre' | 'cushwake' | 'tishman' | 'cardinal' | 'ags' | 'unbranded';
export type DashboadTableTab = 'invoices' | 'payments' | 'openInvoices' | 'paidInvoices';
export type PaidInvoicesTableTab = 'Invoices' | 'Payments';

interface UIState {
  activeDashboardTableTab: DashboadTableTab;
  activePaidInvoicesTableTab: PaidInvoicesTableTab;
  brand: Brand;
  demoMode: boolean;
  demoModeProgramCode: ProgramCodes;
  discoverMoreOpen: boolean;
  excludeSupplementalFee: boolean;
  hasSeenGetPaidNow: boolean;
  inputMfaCodeComplete: boolean;
  invoiceSummaryOpen: boolean;
  onDemandLearnMoreOpen: boolean;
  payEarlyButtonClickCount: number;
  showEarlyPaymentOpportunitiesGlobalNotification: boolean;
  showFeedbackUI: boolean;
  singleToBulkInterstitialClickTimestamps: ISODateString[];
  advancedFiltersOpen: boolean;
}

const initialState: UIState = {
  activeDashboardTableTab: 'invoices',
  activePaidInvoicesTableTab: 'Invoices',
  brand: 'jll',
  demoMode: false,
  demoModeProgramCode: ProgramCodes.NET30,
  discoverMoreOpen: true,
  excludeSupplementalFee: false,
  hasSeenGetPaidNow: false,
  inputMfaCodeComplete: false,
  invoiceSummaryOpen: true,
  onDemandLearnMoreOpen: false,
  payEarlyButtonClickCount: 0,
  showEarlyPaymentOpportunitiesGlobalNotification: false,
  showFeedbackUI: true,
  singleToBulkInterstitialClickTimestamps: [],
  advancedFiltersOpen: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateBrand(state: UIState, action: PayloadAction<Brand>) {
      state.brand = action.payload;
    },
    updateInputMfaCodeComplete(state: UIState, action: PayloadAction<boolean>) {
      state.inputMfaCodeComplete = action.payload;
    },
    updateHasSeenGetPaidNow(state: UIState, action: PayloadAction<boolean>) {
      state.hasSeenGetPaidNow = action.payload;
    },
    updateDemoMode(state: UIState, action: PayloadAction<boolean>) {
      state.demoMode = action.payload;
    },
    updateDemoModeProgramCode(state: UIState, action: PayloadAction<ProgramCodes>) {
      state.demoModeProgramCode = action.payload;
    },
    updateExcludeSupplementalFee(state: UIState, action: PayloadAction<boolean>) {
      state.excludeSupplementalFee = action.payload;
    },
    updateShowFeedbackUI(state: UIState, action: PayloadAction<boolean>) {
      state.showFeedbackUI = action.payload;
    },
    updateSingleToBulkInterstitialClickTimestamps(state: UIState, action: PayloadAction<ISODateString>) { 
      // clear this out after 20 entries to avoid too large of a record:
      if (state.singleToBulkInterstitialClickTimestamps.length === 19) {
        state.singleToBulkInterstitialClickTimestamps = [];
      } else {
        state.singleToBulkInterstitialClickTimestamps.push(action.payload);
      }
    },
    updateShowEarlyPaymentOpportunitiesGlobalNotification(state: UIState, action: PayloadAction<boolean>) { 
      state.showEarlyPaymentOpportunitiesGlobalNotification = action.payload;
    },
    updateInvoiceSummaryOpen(state: UIState, action: PayloadAction<boolean>) { 
      state.invoiceSummaryOpen = action.payload;
    },
    updateDiscoverMoreOpen(state: UIState, action: PayloadAction<boolean>) { 
      state.discoverMoreOpen = action.payload;
    },
    updatePayEarlyButtonClickCount(state: UIState, action: PayloadAction<number>) { 
      state.payEarlyButtonClickCount = action.payload;
    },
    updateActiveDashboardTableTab(state: UIState, action: PayloadAction<DashboadTableTab>) { 
      state.activeDashboardTableTab = action.payload;
    },
    updateActivePaidInvoicesTableTab(state: UIState, action: PayloadAction<PaidInvoicesTableTab>) { 
      state.activePaidInvoicesTableTab = action.payload;
    },
    updateOnDemandLearnMoreOpen(state: UIState, action: PayloadAction<boolean>) { 
      state.onDemandLearnMoreOpen = action.payload;
    },
    updateAdvancedFiltersOpen(state: UIState, action: PayloadAction<boolean>) { 
      state.advancedFiltersOpen = action.payload;
    },
  },
});

export const {
  updateActiveDashboardTableTab,
  updateActivePaidInvoicesTableTab,
  updateBrand,
  updateDemoMode,
  updateDemoModeProgramCode,
  updateDiscoverMoreOpen,
  updateExcludeSupplementalFee,
  updateHasSeenGetPaidNow,
  updateInputMfaCodeComplete,
  updateInvoiceSummaryOpen,
  updateOnDemandLearnMoreOpen,
  updatePayEarlyButtonClickCount,
  updateShowEarlyPaymentOpportunitiesGlobalNotification,
  updateShowFeedbackUI,
  updateSingleToBulkInterstitialClickTimestamps,
  updateAdvancedFiltersOpen,
} = uiSlice.actions;
export default uiSlice.reducer;
