import { useEffect, useMemo } from 'react';
import { ProgramCodes } from '@mesa-labs/mesa-api/dist/types';
import { useDispatch } from '../redux/hooks';
import { updateBrand, updateDemoMode, updateDemoModeProgramCode, updateShowFeedbackUI } from '../redux/slices/ui';
import { updateVendorField } from '../redux/slices/vendor';
import { updateVendorAccountField } from '../redux/slices/vendorAccount';

function useDemoMode(): void {
  const dispatch = useDispatch();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const isDemoMode = useMemo(() => {
    const hasDemoModeParam = params.has('demo');

    return hasDemoModeParam;
  }, [params]);

  const brand = useMemo(() => {
    const brand = params.get('demo');

    switch (brand) {
      case 'cardinal':
        return 'cardinal';
      case 'ags':
        return 'ags';
      default:
      case 'cbre':
        return 'cbre'
    }
  }, [params]);

  useEffect(() => {
    const demoParam = params.get('demo');

    if (demoParam === 'on-demand') {
      dispatch(updateDemoModeProgramCode(ProgramCodes.OD));
    } else if (demoParam === 'pay-on-approval') {
      dispatch(updateDemoModeProgramCode(ProgramCodes.POA));
    } else {
      dispatch(updateDemoModeProgramCode(ProgramCodes.NET30));
    }
  }, [dispatch, params]);

  if (isDemoMode) {
    const businessName = brand === 'ags' ? 'Boulder IT Staffing' : 'Boulder HVAC & Plumbing';

    dispatch(updateDemoMode(true));
    dispatch(updateBrand(brand));
    dispatch(updateVendorField({
      field: 'email',
      value: 'test@boulderhvac.com',
    }));
    dispatch(updateVendorField({
      field: 'name',
      value: businessName,
    }));
    dispatch(updateVendorField({
      field: 'address',
      value: '780 16th St.',
    }));
    dispatch(updateVendorField({
      field: 'city',
      value: 'Boulder',
    }));
    dispatch(updateVendorField({
      field: 'firstName',
      value: 'Ben',
    }));
    dispatch(updateVendorField({
      field: 'lastName',
      value: 'Apel',
    }));
    dispatch(updateVendorField({
      field: 'phoneNumber',
      value: '(123) 456-7890',
    }));
    dispatch(updateVendorField({
      field: 'state',
      value: 'CO',
    }));
    dispatch(updateVendorField({
      field: 'title',
      value: 'CEO',
    }));
    dispatch(updateVendorField({
      field: 'zip',
      value: '80306',
    }));

    dispatch(updateVendorAccountField({
      field: 'name',
      value: 'Mesa Checking Account',
    }));

    dispatch(updateVendorAccountField({
      field: 'accountNumber',
      value: '50952189',
    }));

    dispatch(updateVendorAccountField({
      field: 'confirmAccountNumber',
      value: '50952189',
    }));

    dispatch(updateVendorAccountField({
      field: 'routingNumber',
      value: '631916385',
    }));

    // do not show Feedback UI in demo mode
    dispatch(updateShowFeedbackUI(false));
  }
}

export default useDemoMode;
