import React, { ReactElement, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { types as api } from '@mesa-labs/mesa-api';

import { useSelector } from '../../../redux/hooks';
import useProgressBarSteps from '../../../hooks/useProgressBarSteps';

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
`;

const StepsMobile = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.Laptop}) {
    display: none;
  }
`;

const StepsDesktop = styled.div`
  align-items: center;
  display: none;
  gap: 16px;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.Laptop}) {
    display: flex;
  }
`;

const Step = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const StepNumber = styled.div<{ background: string, borderColor: string, color: string }>`
  align-items: center;
  background: ${(props) => props.background};
  border-radius: 50%;
  border: 1px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  height: 20px;
  line-height: 16px;
  transition: color 300ms ease-in-out,
    background 300ms ease-in-out,
    border 300ms ease-in-out;
  width: 20px;
`;

const StepName = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  transition: color 750ms ease-in-out;
`;

const StepArrow = styled.img`
`;

const CompletedCheckIcon = styled.img`
`;

const MobileProgressLine = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="6" viewBox="0 0 32 6" fill="none">
    <path d="M0 3C0 4.65685 1.34315 6 3 6L29 6C30.6569 6 32 4.65685 32 3C32 1.34315 30.6569 0 29 0L3 0C1.34315 0 0 1.34315 0 3Z" fill={fill} />
  </svg>
)

function ProgressBar(): ReactElement {
  const location = useLocation();
  const customMsa = useSelector((state) => state.onboarding.customMsa);
  const availablePrograms = useSelector((state) => state.onboarding.availablePrograms);
  const referralCode = useSelector((state) => state.vendor.vendor.referralCode);
  const demoCodeProgramCode = useSelector((state) => state.ui.demoModeProgramCode);

  const hasOnDemandProgramOverride = (availablePrograms || []).some((override) => override.programCode === api.ProgramCodes.OD);

  const onDemand = useMemo(() => hasOnDemandProgramOverride || referralCode === api.ProgramCodes.OD || demoCodeProgramCode === api.ProgramCodes.OD,
    [hasOnDemandProgramOverride, referralCode, demoCodeProgramCode]
  );

  const stepContent = useProgressBarSteps(onDemand, customMsa);

  const currentStep = useMemo(
    () => {
      const current = stepContent.find(
        (step) => step.routes.some((route) => location.pathname.includes(route)),
      );

      if (!current) {
        return -1;
      }

      return current.step;
    },
    [location, stepContent],
  );

  const getColorsForStep = (step: number) => {
    if (currentStep === step) {
      return {
        name: '#0E121B',
        borderColor: '#5A05FF',
        background: '#5A05FF',
        number: '#FFFFFF'
      };
    } else if (step > currentStep) {
      return {
        name: '#525866',
        borderColor: '#E1E4EA',
        background: '#FFFFFF',
        number: '#525866',
      };
    }

    return {
      name: '#0E121B',
      borderColor: '#1FC16B',
      background: '#1FC16B',
      number: '#FFFFFF',
    };
  };

  const numVisibleSteps = stepContent.filter((step) => step.visible).length;

  return (
    <ProgressBarContainer>
      <StepsMobile>
        {
          stepContent.map((step, idx) => {
            if (!step.visible) {
              return null;
            }

            if (currentStep > idx) {
              return <MobileProgressLine fill="#1FC16B" />;
            }

            return <MobileProgressLine fill="#E1E4EA" />;
          })
        }
      </StepsMobile>
      <StepsDesktop>
        {
          stepContent.map((step, idx) => {
            if (!step.visible) {
              return null;
            }

            const colors = getColorsForStep(step.step);

            return (
              <>
                <Step key={step.name}>
                  <StepNumber
                    background={colors.background}
                    borderColor={colors.borderColor}
                    color={colors.number}
                  >
                    {currentStep <= idx && idx + 1}
                    {currentStep > idx && <CompletedCheckIcon src="/assets/completed-check-icon.svg" />}
                  </StepNumber>

                  <StepName color={colors.name}>{step.name}</StepName>
                </Step>

                {idx !== numVisibleSteps - 1 && <StepArrow src="/assets/arrow-right.svg" />}
              </>
            );
          })
        }
      </StepsDesktop>
    </ProgressBarContainer>
  );
}

export default ProgressBar;
