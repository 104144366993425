import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BankAccountType, FeeScheme, UUID, VerificationProcess, VerificationStatus,
} from '@mesa-labs/mesa-api/dist/types';

export type VendorAccount = {
  accountNumber?: string;
  confirmAccountNumber?: string;
  bankAccountType: BankAccountType;
  id?: UUID;
  name?: string;
  routingNumber?: string;
  maskedAccountNumber?: string;
  maskedRoutingNumber?: string;
  verificationProcess: VerificationProcess;
  verificationStatus?: VerificationStatus;
  verificationAttempts: number;
  directDebitAuthorization?: string;
};

export type VendorAccountState = {
  vendorAccount: VendorAccount;
  bankVerificationModalAccountId: UUID;
  documentUploadVendorAccountId?: UUID;
  addBankAccountFeeScheme?: FeeScheme;
};

const initialState: VendorAccountState = {
  vendorAccount: {
    accountNumber: undefined,
    confirmAccountNumber: undefined,
    bankAccountType: BankAccountType.CHECKING,
    id: undefined,
    maskedAccountNumber: undefined,
    maskedRoutingNumber: undefined,
    name: undefined,
    routingNumber: undefined,
    verificationProcess: VerificationProcess.MICRO_TRANSACTIONS,
    verificationStatus: undefined,
    verificationAttempts: 0,
    directDebitAuthorization: undefined,
  },
  bankVerificationModalAccountId: '',
  documentUploadVendorAccountId: '',
  addBankAccountFeeScheme: FeeScheme.DISCOUNTING,
};

const vendorAccountSlice = createSlice({
  name: 'vendorAccount',
  initialState,
  reducers: {
    updateVendorAccountField(
      state: VendorAccountState,
      action: PayloadAction<{ field: keyof VendorAccount, value: any }>,
    ) {
      const { payload } = action;

      state.vendorAccount = {
        ...state.vendorAccount,
        [payload.field]: payload.value,
      };
    },
    updateBankVerificationModalAccountId(state: VendorAccountState, action: PayloadAction<UUID>) {
      state.bankVerificationModalAccountId = action.payload;
    },
    updateDocumentUploadVendorAccountId(state: VendorAccountState, action: PayloadAction<UUID>) {
      state.documentUploadVendorAccountId = action.payload;
    },
    updateAddBankAccountFeeScheme(state: VendorAccountState, action: PayloadAction<FeeScheme>) {
      state.addBankAccountFeeScheme = action.payload;
    }
  },
});

export const {
  updateVendorAccountField,
  updateBankVerificationModalAccountId,
  updateDocumentUploadVendorAccountId,
  updateAddBankAccountFeeScheme,
} = vendorAccountSlice.actions;

export default vendorAccountSlice.reducer;
