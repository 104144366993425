import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoiceComparisonOperator, InvoiceDisplayState, ISODateString } from '@mesa-labs/mesa-api/dist/types';

interface AdvancedFiltersState {
  totalOpenAmountConfigured: boolean;
  totalOpenAmountOperator: InvoiceComparisonOperator;
  totalOpenAmount?: number;
  estimatedPaymentDateConfigured: boolean;
  estimatedPaymentDateOperator: InvoiceComparisonOperator;
  estimatedPaymentDate?: ISODateString;
  statusesConfigured: boolean;
  statuses: InvoiceDisplayState[];
  clientIdsConfigured: boolean;
  clientIds: string[];
}

const initialState: AdvancedFiltersState = {
  totalOpenAmountConfigured: false,
  totalOpenAmountOperator: InvoiceComparisonOperator.GTE,
  totalOpenAmount: undefined,
  estimatedPaymentDateConfigured: false,
  estimatedPaymentDateOperator: InvoiceComparisonOperator.GTE,
  estimatedPaymentDate: undefined,
  statusesConfigured: false,
  statuses: [],
  clientIdsConfigured: false,
  clientIds: [],
};

const advancedFiltersSlice = createSlice({
  name: 'advancedFilters',
  initialState,
  reducers: {
    updateTotalOpenAmountOperator(state: AdvancedFiltersState, action: PayloadAction<InvoiceComparisonOperator>) {
      state.totalOpenAmountOperator = action.payload;
    },
    updateTotalOpenAmount(state: AdvancedFiltersState, action: PayloadAction<number | undefined>) {
      state.totalOpenAmount = action.payload;
    },
    updateEstimatedPaymentDateOperator(state: AdvancedFiltersState, action: PayloadAction<InvoiceComparisonOperator>) {
      state.estimatedPaymentDateOperator = action.payload;
    },
    updateEstimatedPaymentDate(state: AdvancedFiltersState, action: PayloadAction<ISODateString | undefined>) {
      state.estimatedPaymentDate = action.payload;
    },
    updateStatuses(state: AdvancedFiltersState, action: PayloadAction<InvoiceDisplayState[]>) {
      state.statuses = action.payload;
    },
    updateClientIds(state: AdvancedFiltersState, action: PayloadAction<string[]>) {
      state.clientIds = action.payload;
    },
    updateConfigured(state: AdvancedFiltersState, action: PayloadAction<{ key: 'totalOpenAmountConfigured' | 'estimatedPaymentDateConfigured' | 'statusesConfigured' | 'clientIdsConfigured', value: boolean }>) {
      state[action.payload.key] = action.payload.value;
    },
    clearAll(state: AdvancedFiltersState) {
      state.totalOpenAmountConfigured = false;
      state.totalOpenAmountOperator = initialState.totalOpenAmountOperator;
      state.totalOpenAmount = initialState.totalOpenAmount;

      state.estimatedPaymentDateConfigured = false;
      state.estimatedPaymentDateOperator = initialState.estimatedPaymentDateOperator;
      state.estimatedPaymentDate = initialState.estimatedPaymentDate;

      state.statusesConfigured = false;
      state.statuses = initialState.statuses;

      state.clientIdsConfigured = false;
      state.clientIds = initialState.clientIds;
    }
  },
});

export const {
  updateTotalOpenAmountOperator,
  updateTotalOpenAmount,
  updateEstimatedPaymentDateOperator,
  updateEstimatedPaymentDate,
  updateStatuses,
  updateClientIds,
  updateConfigured,
  clearAll,
} = advancedFiltersSlice.actions;
export default advancedFiltersSlice.reducer;
