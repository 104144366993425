import { combineReducers } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import advancedFilters from './advancedFilters';
import auth from './auth';
import invoices from './invoices';
import { invoicesApi } from '../api/invoices';
import notifications from './notifications';
import onboarding from './onboarding';
import vendorAccount from './vendorAccount';
import vendor from './vendor';
import { vendorApi } from '../api/vendor';
import ui from './ui';
import payments from './payments';
import { paymentsApi } from '../api/payments';
import modals from './modals';

const vendorTransform = createTransform((inbound) => ({
  ...inbound as Record<string, unknown>,
  // never persist hasAcceptedTos key
  hasAcceptedTos: false,
  hasAcceptedServiceAgreement: false,
  hasAcceptedPartnerServiceAgreement: false,
  signedByName: '',
  partnerSignedByName: '',
}));

const vendorConfig = {
  key: 'vendor',
  storage,
  transforms: [vendorTransform],
};

const reducer = combineReducers({
  advancedFilters,
  auth,
  invoices,
  modals,
  notifications,
  onboarding,
  payments,
  vendorAccount,
  vendor: persistReducer(vendorConfig, vendor),
  ui,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [vendorApi.reducerPath]: vendorApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
});

export default reducer;
