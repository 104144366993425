import {
  CsvFormat,
  RedactedInvoiceResponse,
  SortDirection,
  InvoiceLineItemState,
  UUID,
} from '@mesa-labs/mesa-api/dist/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type InvoiceTimeRange = number; // in days;

export const PaginationLimits = [
  { key: '10', value: 10 },
  { key: '25', value: 25 },
  { key: '50', value: 50 },
  { key: '100', value: 100 },
];

export type ExportFormatType = 'operator' | 'vendor';

export type InvoicesState = {
  invoices: RedactedInvoiceResponse[];
  page: number;
  limit: { key: string; value: number };
  total?: number;
  searchTerm?: string;
  sortField?: string;
  sortDirection: SortDirection;
  exportFormat: CsvFormat;
  // used to determine operator vs. vendor "exportFormat"
  exportFormatType: ExportFormatType;
  excludeStates: InvoiceLineItemState[];
  instantPayoutId?: string;
  mesaOnDemandId?: string;
  selectiveGetPaidNowRows: UUID[];
  selectiveGetPaidNowTotal: number;
};

const initialState: InvoicesState = {
  invoices: [],
  page: 1,
  limit: PaginationLimits[0],
  total: undefined,
  searchTerm: undefined,
  sortField: 'eligibleAt',
  sortDirection: SortDirection.DESCENDING,
  exportFormat: CsvFormat.MESA_CSV_FORMAT,
  exportFormatType: 'vendor',
  excludeStates: [],
  instantPayoutId: undefined,
  mesaOnDemandId: undefined,
  selectiveGetPaidNowRows: [],
  selectiveGetPaidNowTotal: 0,
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    updateInvoices(state: InvoicesState, action: PayloadAction<RedactedInvoiceResponse[]>) {
      state.invoices = action.payload;
    },
    updatePage(state: InvoicesState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateLimit(state: InvoicesState, action: PayloadAction<{ key: string; value: number }>) {
      state.limit = action.payload;
    },
    updateTotal(state: InvoicesState, action: PayloadAction<number>) {
      state.total = action.payload;
    },
    updateSearchTerm(state: InvoicesState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updateSortField(state: InvoicesState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: InvoicesState, action: PayloadAction<SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateExportFormat(state: InvoicesState, action: PayloadAction<CsvFormat>) {
      state.exportFormat = action.payload;
    },
    updateExportFormatType(state: InvoicesState, action: PayloadAction<ExportFormatType>) {
      state.exportFormatType = action.payload;
    },
    updateExcludeStates(state: InvoicesState, action: PayloadAction<InvoiceLineItemState[]>) {
      state.excludeStates = action.payload;
    },
    updateInstantPayoutId(state: InvoicesState, action: PayloadAction<string>) {
      state.instantPayoutId = action.payload;
    },
    updateMesaOnDemandId(state: InvoicesState, action: PayloadAction<string>) {
      state.mesaOnDemandId = action.payload;
    },
    updateSelectiveGetPaidNowRows(state: InvoicesState, action: PayloadAction<UUID[]>) {
      state.selectiveGetPaidNowRows = action.payload;
    },
    updateSelectiveGetPaidNowTotal(state: InvoicesState, action: PayloadAction<number>) {
      state.selectiveGetPaidNowTotal = action.payload;
    },
  },
});

export const {
  updateInvoices,
  updatePage,
  updateLimit,
  updateTotal,
  updateSearchTerm,
  updateSortField,
  updateSortDirection,
  updateExportFormat,
  updateExportFormatType,
  updateExcludeStates,
  updateInstantPayoutId,
  updateMesaOnDemandId,
  updateSelectiveGetPaidNowRows,
  updateSelectiveGetPaidNowTotal,
} = invoicesSlice.actions;
export default invoicesSlice.reducer;
